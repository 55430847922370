import React, {useEffect, useState} from 'react';
import {Box, Container, CssBaseline, Link, Typography} from '@mui/material';
import Form from "./Form";
import {homePath, signInPath} from "../../../routes/routes";
import {useAuth} from "../../../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import {useApi} from "../../../hooks/useApi";
import Header from "../Header";
import {useSnackbar} from 'notistack';
import {clientId, clientSecret} from "../../../constants";
import {userSignInPath, userSignUpPath} from "../../../api/paths";
import {useTranslation} from 'react-i18next';

const SignUp = () => {
    const {setAuthentication, authenticated} = useAuth();
    const {client} = useApi();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation();

    useEffect(() => {
        if (authenticated()) {
            navigate(homePath());
        }
    }, []);

    const handleSignUp = async (fullName, email, password, confirmPassword) => {
        if (password !== confirmPassword) {
            enqueueSnackbar(t('common:signUp.passwordsDoNotMatch'), {variant: 'error'});
            return;
        }

        setLoading(true);

        try {
            const response = await client.post(userSignUpPath, {
                user: {
                    full_name: fullName,
                    email: email,
                    password: password,
                    password_confirmation: confirmPassword
                }
            });
            await signIn(email, password);
        } catch (error) {
            handleRequestError(error);
        }
    };

    const signIn = async (email, password) => {
        setSuccess(true);
        enqueueSnackbar(t('common:signUp.signUpSuccessful'), {variant: 'success'});
        try {
            await client.post(userSignInPath, {
                grant_type: 'password',
                client_id: clientId,
                client_secret: clientSecret,
                redirect_uri: 'http://localhost:3000/',
                email: email,
                password: password
            })
                .then(({data}) => {
                    setAuthentication(data.access_token, data.expires_in, data.refresh_token);
                    window.location.href = homePath();
                })
                .catch(error => handleRequestError(error));
        } catch (error) {
            handleGenericError(error);
        }
    }

    const handleRequestError = (error) => {
        setLoading(false);
        if (error.response && error.response.data && error.response.data.errors) {
            const errorMessages = Object.values(error.response.data.errors).flat().join(' ');
            enqueueSnackbar(errorMessages, {variant: 'error'});
        } else {
            enqueueSnackbar(t('common:signUp.unknownError'), {variant: 'error'});
        }
    }

    const handleGenericError = (error) => {
        setLoading(false);
        enqueueSnackbar(error.message || t('common:signUp.genericError'), {variant: 'error'});
        console.info(error);
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 2
            }}>
                <Header subtitle={t('common:signUp.subtitle')}/>
                {success ? (
                    <Typography variant="h6" align="center" sx={{mt: 2}}>
                        {t('common:signUp.confirmationMessage')}
                    </Typography>
                ) : (
                    <Form
                        loading={loading}
                        handleSignUp={handleSignUp}
                    />
                )}
                {!success && (
                    <Typography variant="body2" sx={{mt: 2}}>
                        {t('common:signUp.termsAndConditions')} <Link
                        href="https://blogfactory.ai/">https://blogfactory.ai/</Link>
                    </Typography>
                )}
                <Typography variant="body2" sx={{mt: 2}}>
                    {t('common:signUp.alreadyHaveAccount')} <Link href={signInPath()}>{t('common:signUp.signIn')}</Link>
                </Typography>
            </Box>
        </Container>
    );
};

export default SignUp;

import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {Alert, Box, Button, CircularProgress, Container, CssBaseline, TextField, Typography} from '@mui/material';
import Header from '../Header';
import {useApi} from "../../../hooks/useApi";
import {userPasswordRecoverEditPath, userPasswordRecoverPath} from "../../../api/paths";
import {useTranslation} from 'react-i18next';

const ChangePassword = () => {
    const {t} = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const {client} = useApi();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [email, setEmail] = useState('');

    useEffect(() => {
        const fetchEmail = async () => {
            const token = searchParams.get('reset_password_token');
            if (!token) {
                setError(t('common:changePassword.invalidOrMissingToken'));
                return;
            }
            setLoading(true);
            try {
                const response = await client.get(userPasswordRecoverEditPath(token));
                setEmail(response.data.email);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                setError(t('common:changePassword.invalidTokenOrExpired'));
            }
        };

        fetchEmail();
    }, [client, searchParams, t]);

    const handleChangePassword = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setError(t('common:changePassword.passwordsDoNotMatch'));
            return;
        }
        setLoading(true);
        setError('');
        setSuccess('');

        const token = searchParams.get('reset_password_token');
        try {
            await client.patch(userPasswordRecoverPath, {
                user: {
                    email: email,
                    reset_password_token: token,
                    password: password,
                    password_confirmation: confirmPassword
                }
            });
            setSuccess(t('common:changePassword.passwordChangedSuccess'));
            setTimeout(() => navigate('/users/sign_in'), 3000);
        } catch (error) {
            setLoading(false);
            const errorMessage = error.response?.data?.error || t('common:changePassword.unknownError');
            setError(errorMessage);
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 2
            }}>
                <Header subtitle={t('common:changePassword.subtitle')}/>
                <Box component="form" onSubmit={handleChangePassword} sx={{mt: 1}}>
                    {loading && <CircularProgress/>}
                    {error && <Alert severity="error" sx={{mb: 2}}>{error}</Alert>}
                    {email && (
                        <>
                            <Typography variant="h6" gutterBottom>
                                {t('common:changePassword.changingPasswordFor', {email})}
                            </Typography>
                            {success && <Alert severity="success" sx={{mb: 2}}>{success}</Alert>}
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label={t('common:changePassword.newPassword')}
                                type="password"
                                id="password"
                                autoComplete="new-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="confirmPassword"
                                label={t('common:changePassword.confirmNewPassword')}
                                type="password"
                                id="confirmPassword"
                                autoComplete="new-password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                sx={{mt: 3, mb: 2, py: 1.5}}
                                disabled={loading}
                            >
                                {loading ? <CircularProgress size={24}
                                                             color="secondary"/> : t('common:changePassword.changePasswordButton')}
                            </Button>
                        </>
                    )}
                </Box>
            </Box>
        </Container>
    );
};

export default ChangePassword;

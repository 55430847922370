// User
export const userSignUpPath = '/api/users';
export const userSignInPath = '/api/oauth/token';
export const userRefreshTokenPath = '/api/oauth/token';
export const userResendConfirmationInstructionsPath = '/api/users/confirmation';

export const userConfirmEmailPath = (confirmationToken) => `/api/users/confirmation?confirmation_token=${confirmationToken}`
export const userPasswordRecoverPath = '/api/users/password';
export const userPasswordRecoverEditPath = (token) => `${userPasswordRecoverPath}/edit?reset_password_token=${token}`;
export const directUploadPath = '/api/direct_uploads';
export const userProfilePath = '/api/v1/me'

// Posts
export const postsPath = '/api/v1/posts';
export const postPath = (postId) => `${postsPath}/${postId}`;

export const paymentCreateSessionPath = '/api/v1/payments/create_checkout_session';

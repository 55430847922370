import React from 'react';

import { Box, CircularProgress } from '@mui/material';

export default function Spinner({
  className,
  color = 'primary.main'
}) {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={className}
      color={color}
    >
      <CircularProgress color="inherit" />
    </Box>
  );
}

import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {Alert, Box, Button, CircularProgress, IconButton, InputAdornment, TextField} from '@mui/material';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';

const Form = ({loading, handleSignIn, error}) => {
    const {t} = useTranslation();
    const {
        register,
        handleSubmit,
        formState: {errors}
    } = useForm();
    const [showPassword, setShowPassword] = useState(false);

    const onSubmit = data => {
        const {email, password} = data;
        handleSignIn(email, password);
    };

    const handleClickShowPassword = () => setShowPassword(!showPassword);

    return (
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}}>
            <TextField
                margin="normal"
                fullWidth
                id="email"
                label={t('common:signIn.form.email')}
                name="email"
                autoComplete="email"
                autoFocus
                {...register("email", {
                    required: t('common:signIn.form.emailRequired'),
                    pattern: {value: /^\S+@\S+$/i, message: t('common:signIn.form.invalidEmail')}
                })}
                error={!!errors.email}
                helperText={errors.email?.message}
            />
            <TextField
                margin="normal"
                fullWidth
                name="password"
                label={t('common:signIn.form.password')}
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                {...register("password", {
                    required: t('common:signIn.form.passwordRequired'),
                    minLength: {value: 6, message: t('common:signIn.form.passwordMinLength')}
                })}
                error={!!errors.password}
                helperText={errors.password?.message}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label={t('common:signIn.form.togglePasswordVisibility')}
                                onClick={handleClickShowPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{mt: 3, mb: 2, py: 1.5}}
                disabled={loading}
            >
                {loading ? <CircularProgress size={24} color="secondary"/> : t('common:signIn.form.signIn')}
            </Button>
            {error && <Alert severity="error" sx={{mt: 2}}>{error}</Alert>}
        </Box>
    );
};

export default Form;

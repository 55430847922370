{
  "postList": {
    "title": "Посты"
  },
  "postTable": {
    "view": "Посмотреть",
    "sendToWordpress": "Отправить в WordPress",
    "delete": "Удалить",
    "featuredImage": "Изображение",
    "keyword": "Ключевое слово",
    "title": "Заголовок",
    "noTitle": "Пока без заголовка",
    "statusLabel": "Статус",
    "createdAt": "Создано",
    "actions": "Действия",
    "status": {
      "queued": "В очереди",
      "in_progress": "В процессе",
      "fetching_images": "Загрузка изображений",
      "finished": "Завершено",
      "temporary_failed": "Временная ошибка",
      "failed": "Ошибка"
    }
  },
  "fabButton": {
    "label": "Создать пост",
    "notEnoughCredits": "У вас недостаточно кредитов. Купите больше кредитов, чтобы продолжить."
  },
  "createPost": {
    "title": "Создать пост",
    "success": "Создание поста начато...",
    "labels": {
      "tags": "Ключевые слова",
      "editorialVoice": "Редакционный стиль",
      "targetAudience": "Целевая аудитория",
      "language": "Язык",
      "createWithImages": "Создать с изображениями (2 кредита за пост)"
    },
    "placeholders": {
      "tags": "Добавьте до 10 ключевых слов",
      "editorialVoice": "Опишите редакционный стиль поста",
      "targetAudience": "Опишите целевую аудиторию поста"
    },
    "helpers": {
      "tags": "Разделяйте ключевые слова запятыми, точками с запятой или пробелами",
      "editorialVoice": "Максимум 2000 символов",
      "targetAudience": "Максимум 2000 символов"
    },
    "validation": {
      "tags": "Необходимо указать хотя бы одно ключевое слово"
    },
    "buttons": {
      "createPost": "Создать пост"
    }
  },
  "postDialog": {
    "copySuccess": "{{fieldName}} скопировано в буфер обмена!",
    "copyFail": "Ошибка копирования {{fieldName}}: {{error}}",
    "postDetails": "Детали поста",
    "featuredImage": "Изображение",
    "downloadImage": "Скачать изображение",
    "keyword": "Ключевое слово",
    "title": "Заголовок",
    "tags": "Теги",
    "excerpt": "Отрывок",
    "content": "Контент",
    "copyKeyword": "Копировать ключевое слово",
    "copyTitle": "Копировать заголовок",
    "copyTags": "Копировать теги",
    "copyExcerpt": "Копировать отрывок",
    "copyContent": "Копировать контент",
    "na": "Н/Д",
    "sendToWordpress": "Отправить в мой WordPress"
  },
  "sendTOWordpressDialog": {
    "uploadStarted": "Начата загрузка в WordPress...",
    "unknownError": "Произошла неизвестная ошибка",
    "sendToWordpress": "Отправить в мой WordPress",
    "uploadSuccess": "Загрузка успешна!",
    "uploadFail": "Ошибка загрузки",
    "wordpressDomain": "Домен WordPress",
    "wordpressUsername": "Имя пользователя WordPress",
    "wordpressAppPassword": "Пароль приложения WordPress",
    "uploading": "Загрузка...",
    "cancel": "Отмена",
    "send": "Отправить"
  },
  "notConfirmed": {
    "emailConfirmationRequired": "Требуется подтверждение электронной почты",
    "pleaseConfirmEmail": "Пожалуйста, подтвердите свой адрес электронной почты для доступа к этому приложению.",
    "confirmationInstructionsResent": "Инструкции по подтверждению были повторно отправлены на ваш email. Нажмите на ссылку, когда получите ее, чтобы подтвердить.",
    "resendConfirmationInstructions": "Повторно отправить инструкции по подтверждению",
    "unknownErrorSupport": "Произошла неизвестная ошибка. Пожалуйста, свяжитесь с нашей службой поддержки.",
    "pleaseWait": "Пожалуйста, подождите {{timer}} секунд перед повторной попыткой."
  },
  "changePassword": {
    "invalidOrMissingToken": "Неверный или отсутствующий токен.",
    "invalidTokenOrExpired": "Неверный или истекший токен.",
    "passwordsDoNotMatch": "Пароли не совпадают.",
    "passwordChangedSuccess": "Пароль успешно изменен! Переадресация на страницу входа...",
    "unknownError": "Произошла неизвестная ошибка. Пожалуйста, свяжитесь с поддержкой.",
    "subtitle": "Изменить пароль",
    "changingPasswordFor": "Изменение пароля для: {{email}}",
    "newPassword": "Новый пароль",
    "confirmNewPassword": "Подтвердите новый пароль",
    "changePasswordButton": "Изменить пароль"
  },
  "forgotPassword": {
    "title": "Забыли пароль",
    "emailLabel": "Электронная почта",
    "sendButton": "Отправить письмо для сброса пароля",
    "instructionsSent": "Инструкции по сбросу пароля были отправлены на вашу электронную почту.",
    "failedToSend": "Не удалось отправить инструкции по сбросу пароля.",
    "unknownError": "Произошла неизвестная ошибка. Пожалуйста, попробуйте еще раз.",
    "rememberPassword": "Вспомнили пароль?",
    "backToSignIn": "Вернуться к входу"
  },
  "signIn": {
    "subtitle": "Войти",
    "forgotPassword": "Забыли пароль?",
    "noAccount": "Нет аккаунта?",
    "signUp": "Зарегистрироваться",
    "noConfirmation": "Не получили письмо с подтверждением?",
    "resendConfirmation": "Повторно отправить инструкции по подтверждению",
    "errorInvalidRequest": "Упс! Кажется, возникла ошибка в вашем запросе. Пожалуйста, проверьте и попробуйте снова.",
    "errorUnauthorizedClient": "У вас нет разрешения на этот запрос. Пожалуйста, проверьте свои учетные данные или свяжитесь с поддержкой.",
    "errorAccessDenied": "Доступ запрещен. У вас нет необходимых разрешений для этого запроса.",
    "errorUnsupportedResponseType": "Этот метод получения токена доступа не поддерживается. Пожалуйста, проверьте метод и попробуйте снова.",
    "errorInvalidScope": "Область вашего запроса недействительна или неизвестна. Пожалуйста, проверьте и попробуйте снова.",
    "errorInvalidGrant": "Указанный вами адрес электронной почты или пароль неверны. Пожалуйста, попробуйте снова.",
    "unknownError": "Произошла неизвестная ошибка. Пожалуйста, свяжитесь с нашей службой поддержки по адресу support@blogfactory.ai для получения помощи.",
    "form": {
      "email": "Электронная почта",
      "password": "Пароль",
      "emailRequired": "Электронная почта обязательна",
      "invalidEmail": "Недействительный адрес электронной почты",
      "passwordRequired": "Пароль обязателен",
      "passwordMinLength": "Пароль должен быть не менее 6 символов",
      "signIn": "Войти",
      "togglePasswordVisibility": "Переключить видимость пароля"
    }
  },
  "signUp": {
    "passwordsDoNotMatch": "Пароли не совпадают.",
    "signUpSuccessful": "Регистрация успешна! Вход...",
    "unknownError": "Произошла неизвестная ошибка. Пожалуйста, свяжитесь с нашей службой поддержки.",
    "genericError": "Произошла неизвестная ошибка. Пожалуйста, свяжитесь с нашей службой поддержки по адресу support@blogfactory.ai для получения помощи.",
    "subtitle": "Зарегистрироваться",
    "confirmationMessage": "Вы получили ссылку в своей почте для подтверждения электронной почты. Нажмите на ссылку, чтобы подтвердить и войти.",
    "termsAndConditions": "Регистрируясь, вы соглашаетесь с нашими условиями использования и политикой конфиденциальности, доступными на",
    "alreadyHaveAccount": "Уже есть аккаунт?",
    "signIn": "Войти",
    "form": {
      "fullName": "Полное имя",
      "email": "Электронная почта",
      "password": "Пароль",
      "confirmPassword": "Подтвердите пароль",
      "fullNameRequired": "Полное имя обязательно",
      "emailRequired": "Электронная почта обязательна",
      "invalidEmail": "Недействительный адрес электронной почты",
      "passwordRequired": "Пароль обязателен",
      "passwordMinLength": "Пароль должен быть не менее 6 символов",
      "confirmPasswordRequired": "Подтверждение пароля обязательно",
      "passwordsDoNotMatch": "Пароли не совпадают",
      "togglePasswordVisibility": "Переключить видимость пароля",
      "toggleConfirmPasswordVisibility": "Переключить видимость подтверждения пароля",
      "signUp": "Зарегистрироваться"
    }
  },
  "emailConfirmation": {
    "unknownError": "Произошла неизвестная ошибка. Пожалуйста, свяжитесь с нашей службой поддержки.",
    "invalidOrMissingToken": "Недействительный или отсутствующий токен подтверждения.",
    "tryAgainOrContactSupport": "Пожалуйста, попробуйте снова или свяжитесь с поддержкой, если проблема сохраняется."
  },
  "resendConfirmationInstructions": {
    "header": "Повторно отправить инструкции по подтверждению",
    "instructionsResent": "Инструкции по подтверждению были повторно отправлены на ваш email.",
    "enterEmail": "Введите свой адрес электронной почты ниже, чтобы повторно отправить инструкции по подтверждению.",
    "emailLabel": "Электронная почта",
    "resendButton": "Повторно отправить инструкции по подтверждению",
    "backToSignIn": "Вернуться к входу",
    "unknownError": "Произошла неизвестная ошибка. Пожалуйста, свяжитесь с нашей службой поддержки."
  },
  "userCreditsButton": {
    "tooltip": "Нажмите здесь, чтобы купить кредиты",
    "credits_one": "{{count}} Кредит",
    "credits_other": "{{count}} Кредитов",
    "buyCredits": "Купить кредиты"
  },
  "buyCreditsDialog": {
    "title": "Купить кредиты",
    "enterNumberOfCredits": "Введите количество кредитов, которые вы хотите купить:",
    "cancel": "Отмена",
    "buyCredits": "Купить кредиты",
    "processing": "Обработка...",
    "invalidNumberOfCredits": "Пожалуйста, введите действительное количество кредитов.",
    "stripeCheckoutFailed": "Ошибка запуска проверки Stripe."
  }
}

import React, {useContext} from 'react';
import {Add} from "@mui/icons-material";
import {Fab} from "@mui/material";
import useModal from "../../hooks/useModal";
import CreatePostDialog from "./CreatePostDialog";
import CurrentUserContext from "../../contexts/current-user-context";
import BuyCreditsDialog from "../../components/UserCreditsButton/BuyCreditsDialog";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";

const AddPostFabButton = ({updateTable}) => {
    const {showModal} = useModal();
    const {currentUser} = useContext(CurrentUserContext);
    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation()


    function handleAddPost() {
        const minimumCredits = 1; // or 2 if creating with images
        if (currentUser.credits >= minimumCredits) {
            showModal(CreatePostDialog, {updateTable});
        } else {
            enqueueSnackbar(t('common:fabButton.notEnoughCredits'), {variant: 'info'});
            showModal(BuyCreditsDialog, {});
        }
    }

    const fabStyle = {
        position: 'fixed',
        bottom: 60,
        right: 15,
    };

    return (
        <Fab
            variant="extended"
            onClick={handleAddPost}
            style={fabStyle}
        >
            <Add/>
            {t('common:fabButton.label')}
        </Fab>
    );
};

export default AddPostFabButton;

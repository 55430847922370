import React, {useCallback, useEffect, useState} from "react";
import {Box, Typography} from "@mui/material";
import AppLayout from "../../components/AppLayout";
import PostTable from "./PostTable";
import AddPostFabButton from "./AddPostFabButton";
import {useApi} from "../../hooks/useApi";
import {mapOperatorToRansack} from "../../helpers/mapOperatorToRansack";
import {postsPath} from "../../api/paths";
import useChannelSubscription from "../../hooks/useChannelSubscription";
import useModal from "../../hooks/useModal";
import PostDialog from "./PostDialog";
import SendToWordpressDialog from "./SendToWordpressDialog";
import {useTranslation} from "react-i18next";

const PostList = () => {
    const {t} = useTranslation();
    const {client} = useApi();
    const {showModal} = useModal();
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    const [filterModel, setFilterModel] = useState({items: []});
    const [sortModel, setSortModel] = useState([{field: 'created_at', sort: 'desc'}]);
    const [isLoading, setIsLoading] = useState(false);

    const fetch = useCallback(() => {
        setIsLoading(true);
        const filters = filterModel.items.reduce((acc, curr) => {
            const ransackOperator = mapOperatorToRansack(curr.operator);
            acc[`q[${curr.field}_${ransackOperator}]`] = curr.value;
            return acc;
        }, {});

        const sorting = sortModel.map(s => `${s.field} ${s.sort}`).join(',');
        const path = `${postsPath}?page=${paginationModel.page + 1}&per_page=${paginationModel.pageSize}&${new URLSearchParams(filters).toString()}&s=${sorting}`;
        client.get(path).then(({data: {posts, meta}}) => {
            setRows(posts);
            setRowCount(meta.total_entries);
            setPaginationModel(prevModel => ({
                ...prevModel,
                hasNextPage: !!meta.next_page,
            }));
            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
        });
    }, [filterModel.items, paginationModel.page, paginationModel.pageSize, sortModel]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    const updateRowIfExists = useCallback((updatedRow) => {
        setRows(prevRows => {
            const rowIndex = prevRows.findIndex(row => row.id === updatedRow.id);
            if (rowIndex !== -1) {
                const newRows = [...prevRows];
                newRows[rowIndex] = updatedRow;
                return newRows;
            }
            return prevRows;
        });
    }, []);

    useChannelSubscription({
        useEffectId: 'PostsChannelId',
        channel: "PostsChannel",
        onChannelReceived: (data) => {
            updateRowIfExists(data);
        },
    });

    const handleSortModelChange = (newSortModel) => {
        setSortModel(newSortModel);
    };

    const handlePaginationModelChange = (newPaginationModel) => {
        setPaginationModel(prevModel => ({
            ...prevModel,
            page: newPaginationModel.page,
            pageSize: newPaginationModel.pageSize,
        }));
    };

    const handleViewItem = (post) => showModal(PostDialog, {post})

    const handleDeleteItem = (post) => {
    }

    const handleSendToWordpress = (post) => {
        showModal(SendToWordpressDialog, {postId: post.id})
    };

    return (
        <AppLayout
            fab={<AddPostFabButton updateTable={fetch}/>}
        >
            <Box>
                <Typography variant="h2">
                    {t('common:postList.title')}
                </Typography>
            </Box>
            <Box>
                <PostTable
                    rows={rows}
                    rowCount={rowCount}
                    paginationModel={paginationModel}
                    sortModel={sortModel}
                    isLoading={isLoading}
                    handleSortModelChange={handleSortModelChange}
                    handlePaginationModelChange={handlePaginationModelChange}
                    viewItem={handleViewItem}
                    sendItemToWordpress={handleSendToWordpress}
                    deleteItem={handleDeleteItem}
                />
            </Box>
        </AppLayout>
    )
}

export default PostList;

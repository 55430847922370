import * as React from 'react';
import {useContext, useState} from 'react';
import {useLocation} from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AuthenticationContext from "../contexts/authentication-context";
import NotConfirmed from "./NotConfirmed";
import {emailConfirmationPath} from '../routes/routes';
import CurrentUserContext from "../contexts/current-user-context";
import UserCreditsButton from "./UserCreditsButton";

export default function AppLayout({children, fab}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const {authenticated, clearAuthentication} = useContext(AuthenticationContext);
    const {currentUser} = useContext(CurrentUserContext);
    const location = useLocation();

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        clearAuthentication();
    };

    const isEmailConfirmationPath = location.pathname === emailConfirmationPath();

    return (
        <>
            <AppBar position="fixed">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                        BlogFactory
                    </Typography>
                    {authenticated() && (
                        <>
                            <UserCreditsButton/>
                            <div>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                >
                                    <AccountCircle/>
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    {/*<MenuItem onClick={handleClose}>Profile</MenuItem>*/}
                                    {/*<MenuItem onClick={handleClose}>My account</MenuItem>*/}
                                    {/*<Divider/>*/}
                                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                                </Menu>
                            </div>
                        </>
                    )}
                </Toolbar>
            </AppBar>
            <Toolbar/>
            {isEmailConfirmationPath || currentUser.confirmed ? (
                <>
                    {children}
                    {fab}
                </>
            ) : (
                <NotConfirmed/>
            )}
        </>
    );
}

import React, {useEffect} from "react";
import {DataGrid} from "@mui/x-data-grid";
import {Box, Chip, IconButton, Menu, MenuItem, Tooltip, Typography,} from "@mui/material";
import {makeStyles} from '@material-ui/core/styles';
import {format} from "date-fns";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    imageContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(1),
        height: '80%',
    },
    image: {
        maxHeight: '100%',
        width: 'auto',
        borderRadius: "10%",
    },
    dataGridContainer: {
        height: 'calc(100vh - 64px - 80px)', // Adjust height to fit within viewport and leave space for the button
        display: 'flex',
        flexDirection: 'column',
    },
    dataGrid: {
        flexGrow: 1,
    },
}));

const renderFeaturedImage = (params) => {
    const classes = useStyles();
    const imageUrl = params.value || 'https://placehold.co/600x400?text=Featured+Image';
    return (
        <Box className={classes.imageContainer}>
            <img src={imageUrl} alt="Featured" className={classes.image}/>
        </Box>
    );
};

const renderKeywords = (params) => {
    return (
        <Box>
            <Tooltip title={params.value} arrow>
                <Chip label={params.value} style={{margin: 2}}/>
            </Tooltip>
        </Box>
    );
};

const renderTitle = (params) => {
    const {value} = params;
    const {t} = useTranslation();

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                height: '100%',
                width: '100%',
            }}
        >
            <Tooltip title={value || t('common:postTable.noTitle')} arrow>
                <Typography noWrap>
                    {value || t('common:postTable.noTitle')}
                </Typography>
            </Tooltip>
        </Box>
    );
};

const statusColors = {
    queued: 'grey',
    in_progress: 'blue',
    fetching_images: 'purple',
    finished: 'green',
    temporary_failed: 'orange',
    failed: 'red',
};

const renderStatus = (params) => {
    const {t} = useTranslation();
    const status = params.value;
    const chipColor = statusColors[status] || 'default';

    return (
        <Box>
            <Chip
                label={t(`common:postTable.status.${status}`)}
                style={{backgroundColor: chipColor, color: 'white'}}
            />
        </Box>
    );
};

const renderCreatedAt = (params) => {
    const date = new Date(params.value);
    return format(date, 'MM/dd/yyyy HH:mm:ss');
};

const RowMenu = ({id, sendItemToWordpress, viewItem, deleteItem}) => {
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    return (
        <Box>
            <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
                <MoreVertIcon/>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                onClick={(event) => event.stopPropagation()}
            >
                <MenuItem onClick={() => viewItem(id)}>{t('common:postTable.view')}</MenuItem>
                <MenuItem onClick={() => sendItemToWordpress(id)}>{t('common:postTable.sendToWordpress')}</MenuItem>
                <MenuItem onClick={() => deleteItem(id)}>{t('common:postTable.delete')}</MenuItem>
            </Menu>
        </Box>
    );
};

const PostTable = ({
                       rows,
                       rowCount,
                       paginationModel,
                       sortModel,
                       isLoading,
                       handleSortModelChange,
                       handlePaginationModelChange,
                       viewItem,
                       deleteItem,
                       sendItemToWordpress,
                   }) => {
    const classes = useStyles();
    const {t} = useTranslation();

    // Ensure initial pagination model is set to the first page
    useEffect(() => {
        if (paginationModel.page !== 0) {
            handlePaginationModelChange({page: 0, pageSize: paginationModel.pageSize});
        }
    }, []);

    // Calculate row height based on the available height
    const rowHeight = (window.innerHeight) / 10; // 64px header, 80px bottom space, 10 rows

    const columns = [
        {
            field: 'featured_image_result',
            headerName: t('common:postTable.featuredImage'),
            flex: 0.5,
            filterable: false,
            renderCell: renderFeaturedImage
        },
        {
            field: 'keyword',
            headerName: t('common:postTable.keyword'),
            flex: 0.5,
            sortable: false,
            renderCell: renderKeywords
        },
        {field: 'title', headerName: t('common:postTable.title'), flex: 1, sortable: false, renderCell: renderTitle},
        {field: 'status', headerName: t('common:postTable.statusLabel'), flex: 0.5, renderCell: renderStatus},
        {
            field: 'created_at',
            headerName: t('common:postTable.createdAt'),
            flex: 0.5,
            filterable: false,
            renderCell: renderCreatedAt
        },
        {
            field: 'actions',
            headerName: t('common:postTable.actions'),
            flex: 0,
            sortable: false,
            filterable: false,
            renderCell: (params) => <RowMenu id={params.row.id} viewItem={params.viewItem}
                                             deleteItem={params.deleteItem}
                                             sendItemToWordpress={params.sendItemToWordpress}/>
        }
    ];

    return (
        <Box className={classes.dataGridContainer}>
            <DataGrid
                className={classes.dataGrid}
                pagination
                paginationMode="server"
                sortingMode="server"
                disableColumnMenu={true}
                disableColumnFilter={true}
                sortModel={sortModel}
                paginationModel={paginationModel}
                onSortModelChange={handleSortModelChange}
                onPaginationModelChange={handlePaginationModelChange}
                loading={isLoading}
                disableRowSelectionOnClick
                disableColumnResize
                columns={columns.map(col => ({
                    ...col,
                    renderCell: col.field === 'actions'
                        ? (params) => <RowMenu id={params.row} viewItem={viewItem} deleteItem={deleteItem}
                                               sendItemToWordpress={sendItemToWordpress}/>
                        : col.renderCell
                }))}
                rows={rows}
                rowCount={rowCount}
                pageSizeOptions={[10]}
                getRowHeight={() => rowHeight}
                onRowClick={(params) => viewItem(params.row)}
            />
        </Box>
    );
}

export default PostTable;

import React, {useState} from 'react';
import {Alert, Box, Button, CircularProgress, Container, CssBaseline, Link, TextField, Typography} from '@mui/material';
import Header from './Header';
import {useApi} from "../../hooks/useApi";
import {signInPath} from "../../routes/routes";
import {userResendConfirmationInstructionsPath} from "../../api/paths";
import {useTranslation} from 'react-i18next';

const ResendConfirmationInstructions = () => {
    const {t} = useTranslation();
    const {client} = useApi();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');

    const handleResend = async () => {
        setLoading(true);
        setError('');
        setSuccess(false);

        try {
            await client.post(userResendConfirmationInstructionsPath, {user: {email}});
            setSuccess(true);
        } catch (error) {
            setLoading(false);
            const errorMessage = error.response?.data?.error || t('common:resendConfirmationInstructions.unknownError');
            setError(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 2
            }}>
                <Header subtitle={t('common:resendConfirmationInstructions.header')}/>
                {success ? (
                    <Typography variant="h6" align="center" sx={{mt: 2}}>
                        {t('common:resendConfirmationInstructions.instructionsResent')}
                    </Typography>
                ) : (
                    <>
                        <Typography variant="body1" align="center">
                            {t('common:resendConfirmationInstructions.enterEmail')}
                        </Typography>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={t('common:resendConfirmationInstructions.emailLabel')}
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            sx={{mt: 3, mb: 2, py: 1.5}}
                            onClick={handleResend}
                            disabled={loading}
                        >
                            {loading ?
                                <CircularProgress size={24}
                                                  color="secondary"/> : t('common:resendConfirmationInstructions.resendButton')}
                        </Button>
                        {error && <Alert severity="error" sx={{mt: 2}}>{error}</Alert>}
                    </>
                )}
                <Typography variant="body2" sx={{mt: 2}}>
                    <Link href={signInPath()}>{t('common:resendConfirmationInstructions.backToSignIn')}</Link>
                </Typography>
            </Box>
        </Container>
    );
};

export default ResendConfirmationInstructions;

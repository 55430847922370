import React, {useContext, useEffect, useState} from 'react';
import {Alert, Box, Button, CircularProgress, Typography} from '@mui/material';
import CurrentUserContext from "../contexts/current-user-context";
import {userResendConfirmationInstructionsPath} from "../api/paths";
import {useApi} from "../hooks/useApi";
import {useTranslation} from "react-i18next";

const NotConfirmed = () => {
    const {client} = useApi();
    const {currentUser} = useContext(CurrentUserContext);
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');
    const [canResend, setCanResend] = useState(true);
    const [timer, setTimer] = useState(0);

    useEffect(() => {
        let timerId;
        if (timer > 0) {
            timerId = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer <= 1) {
                        clearInterval(timerId);
                        setCanResend(true);
                        return 0;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }
        return () => clearInterval(timerId);
    }, [timer]);

    const handleResend = async () => {
        setLoading(true);
        setError('');
        setSuccess(false);

        try {
            await client.post(userResendConfirmationInstructionsPath, {user: {email: currentUser.email}});
            setSuccess(true);
            setCanResend(false);
            setTimer(180); // 3 minutes timer
        } catch (error) {
            setLoading(false);
            const errorMessage = error.response?.data?.error || t('common:notConfirmed.unknownErrorSupport');
            setError(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            textAlign="center"
            padding={2}
        >
            <Typography variant="h4" gutterBottom>
                {t('common:notConfirmed.emailConfirmationRequired')}
            </Typography>
            <Typography variant="body1" gutterBottom>
                {t('common:notConfirmed.pleaseConfirmEmail')}
            </Typography>
            {success && (
                <Typography variant="h6" align="center" sx={{mt: 2}}>
                    {t('common:notConfirmed.confirmationInstructionsResent')}
                </Typography>
            )}
            {!success && (
                <>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleResend}
                        disabled={loading || !canResend}
                    >
                        {loading ? <CircularProgress size={24}
                                                     color="secondary"/> : t('common:notConfirmed.resendConfirmationInstructions')}
                    </Button>
                    {error && <Alert severity="error" sx={{mt: 2}}>{error}</Alert>}
                </>
            )}
            {!canResend && (
                <Typography variant="body2" color="textSecondary" sx={{mt: 2}}>
                    {t('common:notConfirmed.pleaseWait', {timer})}
                </Typography>
            )}
        </Box>
    );
};

export default NotConfirmed;

import {useEffect, useRef, useState} from 'react';
import websocketConsumer from "../websocketConsumer";

const useChannelSubscription = ({
                                    useEffectId,
                                    channel,
                                    consumer = websocketConsumer,
                                    onChannelReceived,
                                    reconnectInterval = 5000, // time in ms to wait before attempting to reconnect
                                    ...other
                                }) => {
    const [channelData, setChannelData] = useState();
    const subscriptionRef = useRef(null);
    const reconnectTimeoutRef = useRef(null);

    const onReceived = data => setChannelData(data);

    const setupSubscription = () => {
        if (subscriptionRef.current) {
            subscriptionRef.current.unsubscribe();
        }

        subscriptionRef.current = consumer.subscriptions.create(
            {channel, ...other},
            {
                received: onReceived,
                disconnected: handleDisconnected,
                connected: handleConnected
            }
        );
    };

    const handleDisconnected = () => {
        clearTimeout(reconnectTimeoutRef.current);
        reconnectTimeoutRef.current = setTimeout(() => {
            setupSubscription();
        }, reconnectInterval);
    };

    const handleConnected = () => {
        clearTimeout(reconnectTimeoutRef.current);
    };

    useEffect(() => {
        setupSubscription();

        return () => {
            clearTimeout(reconnectTimeoutRef.current);
            if (subscriptionRef.current) {
                subscriptionRef.current.unsubscribe();
            }
        };
    }, [useEffectId]);

    useEffect(() => {
        if (!channelData) return;
        onChannelReceived(channelData);
    }, [channelData]);

    return channelData;
};

export default useChannelSubscription;

import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {Box, Button, CircularProgress, IconButton, InputAdornment, TextField} from '@mui/material';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';

const Form = ({loading, handleSignUp}) => {
    const {t} = useTranslation();
    const {
        register,
        handleSubmit,
        formState: {errors},
        watch
    } = useForm();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const password = watch("password");

    const onSubmit = data => {
        const {fullName, email, password, confirmPassword} = data;
        handleSignUp(fullName, email, password, confirmPassword);
    };

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

    return (
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}}>
            <TextField
                margin="normal"
                fullWidth
                id="fullName"
                label={t("common:signUp.form.fullName")}
                name="fullName"
                autoComplete="name"
                autoFocus
                {...register("fullName", {required: t("common:signUp.form.fullNameRequired")})}
                error={!!errors.fullName}
                helperText={errors.fullName?.message}
            />
            <TextField
                margin="normal"
                fullWidth
                id="email"
                label={t("common:signUp.form.email")}
                name="email"
                autoComplete="email"
                {...register("email", {
                    required: t("common:signUp.form.emailRequired"),
                    pattern: {value: /^\S+@\S+$/i, message: t("common:signUp.form.invalidEmail")}
                })}
                error={!!errors.email}
                helperText={errors.email?.message}
            />
            <TextField
                margin="normal"
                fullWidth
                name="password"
                label={t("common:signUp.form.password")}
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="new-password"
                {...register("password", {
                    required: t("common:signUp.form.passwordRequired"),
                    minLength: {value: 6, message: t("common:signUp.form.passwordMinLength")}
                })}
                error={!!errors.password}
                helperText={errors.password?.message}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label={t("common:signUp.form.togglePasswordVisibility")}
                                onClick={handleClickShowPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                margin="normal"
                fullWidth
                name="confirmPassword"
                label={t("common:signUp.form.confirmPassword")}
                type={showConfirmPassword ? 'text' : 'password'}
                id="confirmPassword"
                autoComplete="new-password"
                {...register("confirmPassword", {
                    required: t("common:signUp.form.confirmPasswordRequired"),
                    validate: value => value === password || t("common:signUp.form.passwordsDoNotMatch")
                })}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword?.message}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label={t("common:signUp.form.toggleConfirmPasswordVisibility")}
                                onClick={handleClickShowConfirmPassword}
                                edge="end"
                            >
                                {showConfirmPassword ? <VisibilityOff/> : <Visibility/>}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{mt: 3, mb: 2, py: 1.5}}
                disabled={loading}
            >
                {loading ? <CircularProgress size={24} color="secondary"/> : t("common:signUp.form.signUp")}
            </Button>
        </Box>
    );
};

export default Form;

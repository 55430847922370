import React, {useEffect, useMemo} from 'react';
import {SnackbarProvider as NotistackSnackbarProvider, useSnackbar} from 'notistack';
import {useLocation, useNavigate} from 'react-router-dom';

let useSnackbarRef = null;

export const SnackbarProvider = ({children, ...other}) => {
    const SnackbarUtilsConfigurator = useMemo(
        () => () => {
            useSnackbarRef = useSnackbar();
            return null;
        },
        [],
    );

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const successMessage = params.get('success');
        const warningMessage = params.get('warning');
        const infoMessage = params.get('info');
        const errorMessage = params.get('error');
        const defaultMessage = params.get('default');

        if (successMessage) {
            snackActions.success(successMessage);
        }

        if (warningMessage) {
            snackActions.warning(warningMessage);
        }

        if (infoMessage) {
            snackActions.info(infoMessage);
        }

        if (errorMessage) {
            snackActions.error(errorMessage);
        }

        if (defaultMessage) {
            snackActions.toast(defaultMessage);
        }

        // Clean the URL after displaying the messages
        if (successMessage || warningMessage || infoMessage || errorMessage || defaultMessage) {
            navigate(window.location.pathname, {replace: true});
        }
    }, [location, navigate]);

    return (
        <NotistackSnackbarProvider
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            autoHideDuration={3000}
            {...other}
        >
            <SnackbarUtilsConfigurator/>
            {children}
        </NotistackSnackbarProvider>
    );
};

export const snackActions = {
    success(msg) {
        this.toast(msg, 'success');
    },
    warning(msg) {
        this.toast(msg, 'warning');
    },
    info(msg) {
        this.toast(msg, 'info');
    },
    error(msg) {
        this.toast(msg, 'error');
    },
    toast(msg, variant = 'default') {
        useSnackbarRef.enqueueSnackbar(msg, {variant});
    }
};

import { createContext } from 'react';

export const initialContextState = {
  state: {},
  makeShowModal: () => () => ({
    id: 'id',
    hide: () => {},
    destroy: () => {},
    update: () => {},
  }),
  destroyModal: () => {},
  updateModal: () => {},
  destroyModalsByRootId: () => {},
};

const ModalContext = createContext(initialContextState);

export default ModalContext;

import {Navigate, Route, Routes} from "react-router-dom";
import {
    changePasswordPath,
    emailConfirmationPath,
    forgotPasswordPath,
    postList,
    resendConfirmationInstructions,
    signInPath,
    signUpPath
} from "./routes/routes";
import SignIn from "./features/Users/SignIn";
import React from "react";
import PostList from "./features/PostList";
import ProtectedRouteWrapper from "./components/ProtectedRouteWrapper";
import SignUp from "./features/Users/SignUp";
import ResendConfirmationEmail from "./features/Users/ResendConfirmationEmail";
import EmailConfirmation from "./features/Users/EmailConfirmation";
import ForgotMyPassword from "./features/Users/ForgotMyPassword";
import ChangePassword from "./features/Users/ChangePassword";

export default function AppRoutes() {
    return (
        <Routes>
            <Route
                path='/'
                element={<Navigate replace to={signInPath()}/>}
            />
            <Route
                path={postList()}
                element={
                    <ProtectedRouteWrapper>
                        <PostList/>
                    </ProtectedRouteWrapper>
                }/>
            <Route
                path={signInPath()}
                element={<SignIn/>}
            />
            <Route
                path={signUpPath()}
                element={<SignUp/>}
            />
            <Route
                path={resendConfirmationInstructions()}
                element={<ResendConfirmationEmail/>}
            />
            <Route
                path={emailConfirmationPath()}
                element={
                    <ProtectedRouteWrapper>
                        <EmailConfirmation/>
                    </ProtectedRouteWrapper>
                }
            />
            <Route
                path={forgotPasswordPath()}
                element={<ForgotMyPassword/>}
            />
            <Route
                path={changePasswordPath()}
                element={<ChangePassword/>}
            />
        </Routes>
    );
}
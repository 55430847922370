import React, {useEffect, useState} from "react";
import {Box, Button, Dialog, DialogContent, DialogTitle, IconButton, TextField, Tooltip} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DownloadIcon from '@mui/icons-material/Download';
import {useSnackbar} from "notistack";
import useChannelSubscription from "../../hooks/useChannelSubscription";
import {makeStyles} from "@material-ui/core/styles";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import useModal from "../../hooks/useModal";
import SendToWordpressDialog from "./SendToWordpressDialog";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    contentCopyButton: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
    },
    featuredImageContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    featuredImage: {
        maxWidth: '100%',
        maxHeight: '300px',
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(1),
    },
    contentFieldset: {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '4px',
        padding: '16.5px 14px',
        position: 'relative',
        fontFamily: 'Roboto, sans-serif',
    },
    contentLegend: {
        padding: '0 5px',
        marginLeft: '10px',
    },
    quill: {
        '& .ql-editor': {
            fontFamily: 'Roboto, sans-serif',
            minHeight: '200px',
        },
    },
    dialogActions: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(2),
    }
}));

const PostDialog = ({open, onClose, post}) => {
    const classes = useStyles();
    const {showModal} = useModal();
    const {enqueueSnackbar} = useSnackbar();
    const [currentPost, setCurrentPost] = useState(post);
    const {t} = useTranslation();

    useEffect(() => {
        setCurrentPost(post);
    }, [post]);

    useChannelSubscription({
        useEffectId: 'PostsChannelId',
        channel: "PostsChannel",
        onChannelReceived: (data) => {
            if (data.id === currentPost.id) {
                setCurrentPost(data);
            }
        },
    });

    const handleCopy = (text, fieldName) => {
        navigator.clipboard.writeText(text).then(() => {
            enqueueSnackbar(t('common:postDialog.copySuccess', {fieldName}), {variant: 'success'});
        }).catch((error) => {
            enqueueSnackbar(t('common:postDialog.copyFail', {fieldName, error}), {variant: 'error'});
        });
    };

    const handleSendToWordpress = () => {
        showModal(SendToWordpressDialog, {postId: post.id});
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogTitle>
                {t('common:postDialog.postDetails')}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                {currentPost.featured_image_result && (
                    <Box className={classes.featuredImageContainer}>
                        <img
                            src={currentPost.featured_image_result}
                            alt={t('common:postDialog.featuredImage')}
                            className={classes.featuredImage}
                        />
                        <Button
                            variant="outlined"
                            startIcon={<DownloadIcon/>}
                            href={currentPost.featured_image_result}
                            target="_blank"
                            download
                        >
                            {t('common:postDialog.downloadImage')}
                        </Button>
                    </Box>
                )}
                <Box mb={2}>
                    <TextField
                        label={t('common:postDialog.keyword')}
                        value={currentPost.keyword || t('common:postDialog.na')}
                        fullWidth
                        disabled
                        InputProps={{
                            endAdornment: (
                                <Tooltip title={t('common:postDialog.copyKeyword')} arrow>
                                    <IconButton
                                        onClick={() => handleCopy(currentPost.keyword, t('common:postDialog.keyword'))}>
                                        <FileCopyIcon/>
                                    </IconButton>
                                </Tooltip>
                            )
                        }}
                    />
                </Box>
                <Box mb={2}>
                    <TextField
                        label={t('common:postDialog.title')}
                        value={currentPost.title || t('common:postDialog.na')}
                        fullWidth
                        disabled
                        InputProps={{
                            endAdornment: (
                                <Tooltip title={t('common:postDialog.copyTitle')} arrow>
                                    <IconButton
                                        onClick={() => handleCopy(currentPost.title, t('common:postDialog.title'))}>
                                        <FileCopyIcon/>
                                    </IconButton>
                                </Tooltip>
                            )
                        }}
                    />
                </Box>
                <Box mb={2}>
                    <TextField
                        label={t('common:postDialog.tags')}
                        value={currentPost.tags || t('common:postDialog.na')}
                        fullWidth
                        disabled
                        InputProps={{
                            endAdornment: (
                                <Tooltip title={t('common:postDialog.copyTags')} arrow>
                                    <IconButton
                                        onClick={() => handleCopy(currentPost.tags, t('common:postDialog.tags'))}>
                                        <FileCopyIcon/>
                                    </IconButton>
                                </Tooltip>
                            )
                        }}
                    />
                </Box>
                <Box mb={2}>
                    <TextField
                        label={t('common:postDialog.excerpt')}
                        value={currentPost.excerpt || t('common:postDialog.na')}
                        fullWidth
                        multiline
                        disabled
                        InputProps={{
                            endAdornment: (
                                <Tooltip title={t('common:postDialog.copyExcerpt')} arrow>
                                    <IconButton
                                        onClick={() => handleCopy(currentPost.excerpt, t('common:postDialog.excerpt'))}>
                                        <FileCopyIcon/>
                                    </IconButton>
                                </Tooltip>
                            )
                        }}
                    />
                </Box>
                <Box mb={2} position="relative">
                    <Box
                        component="fieldset"
                        className={classes.contentFieldset}
                    >
                        <legend className={classes.contentLegend}>{t('common:postDialog.content')}</legend>
                        <ReactQuill
                            value={currentPost.content || ''}
                            readOnly={true}
                            theme="bubble"
                            className={classes.quill}
                        />
                        <Tooltip title={t('common:postDialog.copyContent')} arrow>
                            <IconButton
                                onClick={() => handleCopy(currentPost.content, t('common:postDialog.content'))}
                                className={classes.contentCopyButton}
                            >
                                <FileCopyIcon/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
                <Box className={classes.dialogActions}>
                    <Button variant="contained" color="primary" onClick={handleSendToWordpress}>
                        {t('common:postDialog.sendToWordpress')}
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default PostDialog;

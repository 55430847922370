import axios from "axios";
import {signInPath} from "../routes/routes";
import {clientId, clientSecret, host, nodeEnv} from "../constants";
import {userRefreshTokenPath} from "./paths";

const PROTOCOL = nodeEnv === 'production' ? 'https://' : 'http://';
const BASE_URL = `${PROTOCOL}${host}`;

class ApiClient {
    constructor(authData) {
        this.auth = authData;
        this.client = axios.create({
            baseURL: BASE_URL,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            timeout: 10000,
        });

        this.client.interceptors.request.use(this.handleRequest, this.handleError);
        this.client.interceptors.response.use(this.handleResponse, this.handleResponseError);
    }

    handleRequest = async (config) => {
        if (this.auth.authenticated) {
            const isTokenExpired = new Date().getTime() >= this.auth.expiresAt;
            if (isTokenExpired) {
                await this.refreshToken();
            }
            config.headers['Authorization'] = `Bearer ${this.auth.accessToken}`;
        }
        return config;
    };

    handleResponse = (response) => {
        return response;
    };

    handleError = (error) => {
        return Promise.reject(error);
    };

    handleResponseError = async (error) => {
        if (error.response && (error.response.status === 401)) {
            if (window.location.pathname !== signInPath()) {
                this.auth.clearAuthentication();
            }
        }
        return Promise.reject(error);
    };

    refreshToken = async () => {
        try {
            const response = await axios.post(userRefreshTokenPath, {
                grant_type: 'refresh_token',
                refresh_token: this.auth.refreshToken,
                client_id: clientId,
                client_secret: clientSecret,
            });
            const {access_token, expires_in, refresh_token} = response.data;
            this.auth.setAuthentication(access_token, expires_in, refresh_token);
        } catch (error) {
            throw new Error('Failed to refresh token');
        }
    };
}

export default ApiClient;

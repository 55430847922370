import React, {useEffect, useState} from 'react';
import {Navigate, useNavigate, useSearchParams} from 'react-router-dom';
import {Alert, Box, CircularProgress, Typography} from '@mui/material';
import {useApi} from '../../hooks/useApi';
import {homePath, postList} from '../../routes/routes';
import {userConfirmEmailPath} from "../../api/paths";
import {useTranslation} from 'react-i18next';

const EmailConfirmation = () => {
    const {t} = useTranslation();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const {client} = useApi();
    const navigate = useNavigate();

    useEffect(() => {
        const confirmEmail = async () => {
            const confirmationToken = searchParams.get('confirmation_token');
            if (confirmationToken) {
                try {
                    await client.get(userConfirmEmailPath(confirmationToken));
                    setLoading(false);
                    setError(null);
                    navigate(homePath());
                } catch (err) {
                    setLoading(false);
                    setError(err.response?.data?.error || t('common:emailConfirmation.unknownError'));
                }
            } else {
                setLoading(false);
                setError(t('common:emailConfirmation.invalidOrMissingToken'));
            }
        };

        confirmEmail();
    }, [searchParams]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress/>
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100vh"
                 textAlign="center">
                <Alert severity="error" sx={{mb: 2}}>{error}</Alert>
                <Typography variant="body1">{t('common:emailConfirmation.tryAgainOrContactSupport')}</Typography>
            </Box>
        );
    }

    return <Navigate to={postList()}/>;
};

export default EmailConfirmation;

import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {useSnackbar} from 'notistack';
import Cookies from 'js-cookie';
import useChannelSubscription from "../../hooks/useChannelSubscription";
import {useApi} from "../../hooks/useApi";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {useTranslation} from "react-i18next";

const SendToWordpressDialog = ({open, onClose, postId}) => {
    const {enqueueSnackbar} = useSnackbar();
    const {client} = useApi();
    const {t} = useTranslation();
    const [domain, setDomain] = useState('');
    const [username, setUsername] = useState('');
    const [appPassword, setAppPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(null); // null, 'uploading', 'success', 'error'
    const [error, setError] = useState('');

    useEffect(() => {
        if (open) {
            const savedDomain = Cookies.get('wordpress_domain');
            const savedUsername = Cookies.get('wordpress_username');
            const savedAppPassword = Cookies.get('wordpress_app_password');

            if (savedDomain) setDomain(savedDomain);
            if (savedUsername) setUsername(savedUsername);
            if (savedAppPassword) setAppPassword(savedAppPassword);
        }
    }, [open]);

    useChannelSubscription({
        useEffectId: `WordpressUploadChannel_${postId}`,
        channel: "WordpressUploadChannel",
        onChannelReceived: (data) => {
            if (data.post_id === postId) {
                if (data.status === 'success') {
                    setStatus('success');
                    setLoading(false);
                } else if (data.status === 'error') {
                    setStatus('error');
                    setError(data.error);
                    setLoading(false);
                }
            }
        },
    });

    const handleSend = async () => {
        setLoading(true);
        setStatus('uploading');
        setError('');

        Cookies.set('wordpress_domain', domain);
        Cookies.set('wordpress_username', username);
        Cookies.set('wordpress_app_password', appPassword);

        try {
            await client.post('/api/v1/posts/send_post_to_wordpress', {
                post_id: postId,
                wordpress_domain: domain,
                wordpress_username: username,
                wordpress_password: appPassword,
            });
            enqueueSnackbar(t('common:sendTOWordpressDialog.uploadStarted'), {variant: 'info'});
        } catch (error) {
            setLoading(false);
            setStatus('error');
            setError(error.response?.data?.error || t('common:sendTOWordpressDialog.unknownError'));
        }
    };

    const handleClose = () => {
        setDomain('');
        setUsername('');
        setAppPassword('');
        setLoading(false);
        setStatus(null);
        setError('');
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                {t('common:sendTOWordpressDialog.sendToWordpress')}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                {status === 'success' ? (
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <CheckCircleOutlineIcon color="success" sx={{fontSize: 80}}/>
                        <Typography variant="h6"
                                    sx={{mt: 2}}>{t('common:sendTOWordpressDialog.uploadSuccess')}</Typography>
                    </Box>
                ) : status === 'error' ? (
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <ErrorOutlineIcon color="error" sx={{fontSize: 80}}/>
                        <Typography variant="h6"
                                    sx={{mt: 2}}>{t('common:sendTOWordpressDialog.uploadFail')}</Typography>
                        <Typography variant="body1" color="error">{error}</Typography>
                    </Box>
                ) : (
                    <>
                        <TextField
                            label={t('common:sendTOWordpressDialog.wordpressDomain')}
                            value={domain}
                            onChange={(e) => setDomain(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label={t('common:sendTOWordpressDialog.wordpressUsername')}
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label={t('common:sendTOWordpressDialog.wordpressAppPassword')}
                            type="password"
                            value={appPassword}
                            onChange={(e) => setAppPassword(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        {status === 'uploading' && (
                            <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                                <CircularProgress/>
                                <Typography variant="body1"
                                            sx={{ml: 2}}>{t('common:sendTOWordpressDialog.uploading')}</Typography>
                            </Box>
                        )}
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    {t('common:sendTOWordpressDialog.cancel')}
                </Button>
                <Button
                    onClick={handleSend}
                    color="primary"
                    disabled={loading || status === 'success'}
                    variant="contained"
                >
                    {status === 'uploading' ? t('common:sendTOWordpressDialog.uploading') : t('common:sendTOWordpressDialog.send')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SendToWordpressDialog;

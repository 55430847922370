import React from "react";
import {
    Experimental_CssVarsProvider as CssVarsProvider,
    experimental_extendTheme as extendTheme,
} from '@mui/material/styles';

import {grey} from '@mui/material/colors';

const theme = extendTheme({
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    main: grey[600],
                },
            },
        },
        dark: {
            palette: {
                primary: {
                    main: grey[400],
                },
            },
        },
    },
});

const Theme = ({children}) => {
    return (
        <CssVarsProvider theme={theme}>
            {children}
        </CssVarsProvider>
    );
};

export default Theme;

import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import enUSCommon from './locales/en-US/common.json'
import ptBRCommon from './locales/pt-BR/common.json'
import ruCommon from './locales/ru/common.json'

import {nodeEnv} from "./constants";

i18n
    .use(HttpBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en-US',
        debug: nodeEnv !== 'production',
        interpolation: {
            escapeValue: false, // React already safes from xss
        },
        resources: {
            'en-US': {
                common: enUSCommon
            },
            'pt-BR': {
                common: ptBRCommon
            },
            'ru': {
                common: ruCommon
            }
        },
    });

export default i18n;

import React, {useEffect} from 'react';
import {Navigate} from 'react-router-dom';
import {signInPath} from "../routes/routes";
import {useAuth} from "../hooks/useAuth";
import CurrentUserProvider from "../providers/CurrentUserProvider";

const ProtectedRouteWrapper = ({ children }) => {
    const { authenticated } = useAuth();

    if (!authenticated()) {
        return <Navigate to={signInPath()} replace />;
    }

    return (
        <CurrentUserProvider>
            {children}
        </CurrentUserProvider>
    );
};

export default ProtectedRouteWrapper;

import {Typography} from "@mui/material";
import React from "react";

const Header = ({subtitle}) => {
    return (
        <>
            <Typography component="h1" variant="h4" sx={{fontFamily: 'Roboto', fontWeight: 'bold', color: '#1976d2'}}>
                BlogFactory
            </Typography>
            <Typography variant="h6" gutterBottom>
                {subtitle}
            </Typography>
        </>
    );
}

export default Header;
import { useContext, useRef } from 'react';

import ModalContext from "../contexts/modal-context";
import uid from "../helpers/uid";

export default function useModal() {
  const {
    makeShowModal,
    destroyModalsByRootId: destroy,
    ...otherContextProps
  } = useContext(ModalContext);
  const id = useRef(uid(6));

  return { showModal: makeShowModal(id.current), ...otherContextProps };
}

import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useApi} from "../../hooks/useApi";
import {loadStripe} from '@stripe/stripe-js';
import {stripePublicKey} from "../../constants";
import {paymentCreateSessionPath} from "../../api/paths";
import {useTranslation} from 'react-i18next';

// Load Stripe outside of a component's render to avoid recreating the Stripe object on every render.
const stripePromise = loadStripe(stripePublicKey);

const BuyCreditsDialog = ({open, onClose}) => {
    const {t} = useTranslation();
    const {client} = useApi();
    const {enqueueSnackbar} = useSnackbar();
    const [credits, setCredits] = useState(1);
    const [loading, setLoading] = useState(false);

    const handleBuyCredits = async () => {
        if (credits <= 0) {
            enqueueSnackbar(t('common:buyCreditsDialog.invalidNumberOfCredits'), {variant: 'error'});
            return;
        }

        setLoading(true);
        try {
            const response = await client.post(paymentCreateSessionPath, {credits});
            const {sessionId} = response.data;
            const stripe = await stripePromise;
            const {error} = await stripe.redirectToCheckout({sessionId});
            if (error) {
                enqueueSnackbar(error.message, {variant: 'error'});
            }
        } catch (error) {
            enqueueSnackbar(t('common:buyCreditsDialog.stripeCheckoutFailed'), {variant: 'error'});
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{t('common:buyCreditsDialog.title')}</DialogTitle>
            <DialogContent>
                <Typography>{t('common:buyCreditsDialog.enterNumberOfCredits')}</Typography>
                <TextField
                    type="number"
                    fullWidth
                    value={credits}
                    onChange={(e) => setCredits(Number(e.target.value))}
                    margin="dense"
                    inputProps={{min: 1}}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">{t('common:buyCreditsDialog.cancel')}</Button>
                <Button onClick={handleBuyCredits} color="primary" disabled={loading}>
                    {loading ? t('common:buyCreditsDialog.processing') : t('common:buyCreditsDialog.buyCredits')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BuyCreditsDialog;

import React, {useEffect, useMemo, useState} from 'react';
import AuthenticationContext from "../contexts/authentication-context";
import {emailConfirmationPath, homePath, signInPath} from "../routes/routes";
import {useLocation, useNavigate} from "react-router-dom";

const AuthenticationProvider = ({children}) => {
    const initialState = JSON.parse(localStorage.getItem('auth')) || {};
    const [accessToken, setAccessToken] = useState(initialState.access_token);
    const [expiresAt, setExpiresAt] = useState(initialState.expires_at);
    const [refreshToken, setRefreshToken] = useState(initialState.refresh_token);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const auth = localStorage.getItem('auth');
        if (auth) {
            const {access_token, expires_at, refresh_token} = JSON.parse(auth);
            setAccessToken(access_token);
            setExpiresAt(expires_at);
            setRefreshToken(refresh_token);
            if (authenticated() && location.pathname !== emailConfirmationPath()) navigate(homePath());
        }
    }, []);

    const setAuthentication = (returnAccessToken, returnExpiresIn, returnRefreshToken) => {
        const nextExpiresAt = new Date().getTime() + returnExpiresIn * 1000;
        localStorage.setItem('auth', JSON.stringify({
            access_token: returnAccessToken,
            expires_at: nextExpiresAt,
            refresh_token: returnRefreshToken,
        }));
        setAccessToken(returnAccessToken);
        setExpiresAt(nextExpiresAt);
        setRefreshToken(returnRefreshToken);
    };

    const clearAuthentication = () => {
        localStorage.removeItem('auth');
        setAccessToken(null);
        setExpiresAt(null);
        setRefreshToken(null);
        window.location.href = signInPath();
    };

    const authenticated = () => {
        return !!accessToken && new Date().getTime() < expiresAt
    };

    const value = useMemo(() => ({
        accessToken,
        refreshToken,
        expiresAt,
        authenticated,
        setAuthentication,
        clearAuthentication,
    }), [accessToken]);

    return (
        <AuthenticationContext.Provider value={value}>
            {children}
        </AuthenticationContext.Provider>
    );
};

export default AuthenticationProvider;

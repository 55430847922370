{
  "postList": {
    "title": "Posts"
  },
  "postTable": {
    "view": "View",
    "sendToWordpress": "Send to WordPress",
    "delete": "Delete",
    "featuredImage": "Featured Image",
    "keyword": "Keyword",
    "title": "Title",
    "noTitle": "No Title Yet",
    "statusLabel": "Status",
    "createdAt": "Created At",
    "actions": "Actions",
    "status": {
      "queued": "Queued",
      "in_progress": "In Progress",
      "fetching_images": "Fetching Images",
      "finished": "Finished",
      "temporary_failed": "Temporary Failed",
      "failed": "Failed"
    }
  },
  "fabButton": {
    "label": "Create a Post",
    "notEnoughCredits": "You don\\'t have enough credits. Please buy credits to proceed."
  },
  "createPost": {
    "title": "Create Post",
    "success": "Post creation started...",
    "labels": {
      "tags": "Keywords",
      "editorialVoice": "Editorial Voice",
      "targetAudience": "Target Audience",
      "language": "Language",
      "createWithImages": "Create with Images (2 credits per post)"
    },
    "placeholders": {
      "tags": "Add up to 10 Keywords",
      "editorialVoice": "Describe the editorial voice for the post",
      "targetAudience": "Describe the target audience for the post"
    },
    "helpers": {
      "tags": "Separate keywords with commas or semicolons.",
      "editorialVoice": "Maximum 2000 characters",
      "targetAudience": "Maximum 2000 characters"
    },
    "validation": {
      "tags": "At least one tag is required"
    },
    "buttons": {
      "createPost": "Create Post"
    }
  },
  "postDialog": {
    "copySuccess": "{{fieldName}} copied to clipboard!",
    "copyFail": "Failed to copy {{fieldName}}: {{error}}",
    "postDetails": "Post Details",
    "featuredImage": "Featured Image",
    "downloadImage": "Download Image",
    "keyword": "Keyword",
    "title": "Title",
    "tags": "Tags",
    "excerpt": "Excerpt",
    "content": "Content",
    "copyKeyword": "Copy Keyword",
    "copyTitle": "Copy Title",
    "copyTags": "Copy Tags",
    "copyExcerpt": "Copy Excerpt",
    "copyContent": "Copy Content",
    "na": "N/A",
    "sendToWordpress": "Send to My Wordpress"
  },
  "sendTOWordpressDialog": {
    "uploadStarted": "Uploading to WordPress started...",
    "unknownError": "An unknown error occurred",
    "sendToWordpress": "Send to My WordPress",
    "uploadSuccess": "Upload Successful!",
    "uploadFail": "Upload Failed",
    "wordpressDomain": "WordPress Domain",
    "wordpressUsername": "WordPress Username",
    "wordpressAppPassword": "WordPress Application Password",
    "uploading": "Uploading...",
    "cancel": "Cancel",
    "send": "Send"
  },
  "notConfirmed": {
    "emailConfirmationRequired": "Email Confirmation Required",
    "pleaseConfirmEmail": "Please confirm your email address to access this application.",
    "confirmationInstructionsResent": "Confirmation instructions have been resent to your email. Click the link when you receive it to confirm.",
    "resendConfirmationInstructions": "Resend Confirmation Instructions",
    "unknownErrorSupport": "An unknown error occurred. Please contact our support team.",
    "pleaseWait": "Please wait {{timer}} seconds before trying again."
  },
  "changePassword": {
    "invalidOrMissingToken": "Invalid or missing token.",
    "invalidTokenOrExpired": "Invalid token or token expired.",
    "passwordsDoNotMatch": "Passwords do not match.",
    "passwordChangedSuccess": "Password changed successfully! Redirecting to sign in...",
    "unknownError": "An unknown error occurred. Please contact support.",
    "subtitle": "Change Password",
    "changingPasswordFor": "Changing password for: {{email}}",
    "newPassword": "New Password",
    "confirmNewPassword": "Confirm New Password",
    "changePasswordButton": "Change Password"
  },
  "forgotPassword": {
    "title": "Forgot Password",
    "emailLabel": "Email Address",
    "sendButton": "Send Password Reset Email",
    "instructionsSent": "Password reset instructions have been sent to your email.",
    "failedToSend": "Failed to send password reset instructions.",
    "unknownError": "An unknown error occurred. Please try again.",
    "rememberPassword": "Remembered your password?",
    "backToSignIn": "Back to Sign In"
  },
  "signIn": {
    "subtitle": "Sign In",
    "forgotPassword": "Forgot your password?",
    "noAccount": "Don't have an account?",
    "signUp": "Sign Up",
    "noConfirmation": "Didn't receive confirmation email?",
    "resendConfirmation": "Resend Confirmation Instructions",
    "errorInvalidRequest": "Oops! It looks like there was a mistake in your request. Please check and try again.",
    "errorUnauthorizedClient": "You don't have permission to make this request. Please verify your credentials or contact support.",
    "errorAccessDenied": "Access denied. You do not have the necessary permissions for this request.",
    "errorUnsupportedResponseType": "This method of obtaining an access token is not supported. Please check the method and try again.",
    "errorInvalidScope": "The scope of your request is invalid or unknown. Please check and try again.",
    "errorInvalidGrant": "The email address or password you entered is incorrect. Please try again.",
    "unknownError": "An unknown error occurred. Please contact our support team at support@blogfactory.ai for help.",
    "form": {
      "email": "Email",
      "password": "Password",
      "emailRequired": "Email is required",
      "invalidEmail": "Invalid email address",
      "passwordRequired": "Password is required",
      "passwordMinLength": "Password must be at least 6 characters",
      "signIn": "Sign In",
      "togglePasswordVisibility": "Toggle password visibility"
    }
  },
  "signUp": {
    "passwordsDoNotMatch": "Passwords do not match.",
    "signUpSuccessful": "Sign up successful! Signing you in...",
    "unknownError": "An unknown error occurred. Please contact our support team.",
    "genericError": "An unknown error occurred. Please contact our support team at support@blogfactory.ai for help.",
    "subtitle": "Sign Up",
    "confirmationMessage": "You received a link in your mailbox to confirm your email. Click the link to confirm and sign in.",
    "termsAndConditions": "By registering, you agree with our terms and conditions and privacy policy available at",
    "alreadyHaveAccount": "Already have an account?",
    "signIn": "Sign In",
    "form": {
      "fullName": "Full Name",
      "email": "Email",
      "password": "Password",
      "confirmPassword": "Confirm Password",
      "fullNameRequired": "Full Name is required",
      "emailRequired": "Email is required",
      "invalidEmail": "Invalid email address",
      "passwordRequired": "Password is required",
      "passwordMinLength": "Password must be at least 6 characters",
      "confirmPasswordRequired": "Confirm Password is required",
      "passwordsDoNotMatch": "Passwords do not match",
      "togglePasswordVisibility": "Toggle password visibility",
      "toggleConfirmPasswordVisibility": "Toggle confirm password visibility",
      "signUp": "Sign Up"
    }
  },
  "emailConfirmation": {
    "unknownError": "An unknown error occurred. Please contact our support team.",
    "invalidOrMissingToken": "Invalid or missing confirmation token.",
    "tryAgainOrContactSupport": "Please try again or contact support if the issue persists."
  },
  "resendConfirmationInstructions": {
    "header": "Resend Confirmation Instructions",
    "instructionsResent": "Confirmation instructions have been resent to your email.",
    "enterEmail": "Enter your email address below to resend the confirmation instructions.",
    "emailLabel": "Email Address",
    "resendButton": "Resend Confirmation Instructions",
    "backToSignIn": "Back to Sign In",
    "unknownError": "An unknown error occurred. Please contact our support team."
  },
  "userCreditsButton": {
    "tooltip": "Click here to buy credits",
    "credits_one": "{{count}} Credit",
    "credits_other": "{{count}} Credits",
    "buyCredits": "{{count}} Credits - Buy more"
  },
  "buyCreditsDialog": {
    "title": "Buy Credits",
    "enterNumberOfCredits": "Enter the number of credits you want to buy:",
    "cancel": "Cancel",
    "buyCredits": "Buy Credits",
    "processing": "Processing...",
    "invalidNumberOfCredits": "Please enter a valid number of credits.",
    "stripeCheckoutFailed": "Failed to initiate Stripe checkout."
  }
}
import React, {useEffect, useState} from 'react';
import CurrentUserContext from "../contexts/current-user-context";
import Spinner from "../components/Spinner";
import {Box} from "@mui/material";
import {userProfilePath} from "../api/paths";
import {useApi} from "../hooks/useApi";
import {useAuth} from "../hooks/useAuth";
import useChannelSubscription from "../hooks/useChannelSubscription";

export default function CurrentUserProvider({children}) {
    const [currentUser, setCurrentUser] = useState({});
    const [isLoading, setIsLoading] = useState(true); // Set initial loading state to true
    const {accessToken, authenticated} = useAuth();
    const {client} = useApi();

    useEffect(() => {
        if (authenticated()) {
            client.get(userProfilePath)
                .then(({data: {user}}) => {
                    setCurrentUser(user);
                    setIsLoading(false);
                })
                .catch(() => setIsLoading(false)); // Ensure loading state is reset on error
        }
    }, [accessToken]);

    useChannelSubscription({
        useEffectId: currentUser?.id, // Use user ID to manage subscription lifecycle
        channel: 'UserChannel',
        user_id: currentUser?.id,
        onChannelReceived: (data) => {
            console.info(data);
            setCurrentUser(data);
        }
    });

    return (
        <CurrentUserContext.Provider value={{currentUser, setCurrentUser}}>
            {isLoading ? <Box width="100vw" height="100vh"><Spinner/></Box> : children}
        </CurrentUserContext.Provider>
    );
}

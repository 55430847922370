{
  "postList": {
    "title": "Posts"
  },
  "postTable": {
    "view": "Ver",
    "sendToWordpress": "Enviar para o WordPress",
    "delete": "Excluir",
    "featuredImage": "Imagem em Destaque",
    "keyword": "Palavra-chave",
    "title": "Título",
    "noTitle": "Sem Título Ainda",
    "statusLabel": "Status",
    "createdAt": "Criado em",
    "actions": "Ações",
    "status": {
      "queued": "Na Fila",
      "in_progress": "Em Progresso",
      "fetching_images": "Buscando Imagens",
      "finished": "Finalizado",
      "temporary_failed": "Falha Temporária",
      "failed": "Falhou"
    }
  },
  "fabButton": {
    "label": "Criar um Post",
    "notEnoughCredits": "Você não tem créditos suficientes. Compre mais créditos para prosseguir."
  },
  "createPost": {
    "title": "Criar Post",
    "success": "Criação do post iniciada...",
    "labels": {
      "tags": "Palavras-chave",
      "editorialVoice": "Voz Editorial",
      "targetAudience": "Público Alvo",
      "language": "Idioma",
      "createWithImages": "Criar com Imagens (2 créditos por post)"
    },
    "placeholders": {
      "tags": "Adicionar até 10 Palavras-chave",
      "editorialVoice": "Descreva a voz editorial do post",
      "targetAudience": "Descreva o público alvo do post"
    },
    "helpers": {
      "tags": "Separe as palavras-chave com vírgulas ou ponto e vírgula",
      "editorialVoice": "Máximo de 2000 caracteres",
      "targetAudience": "Máximo de 2000 caracteres"
    },
    "validation": {
      "tags": "Pelo menos uma palavra-chave é necessária"
    },
    "buttons": {
      "createPost": "Criar Post"
    }
  },
  "postDialog": {
    "copySuccess": "{{fieldName}} copiado para a área de transferência!",
    "copyFail": "Falha ao copiar {{fieldName}}: {{error}}",
    "postDetails": "Detalhes do Post",
    "featuredImage": "Imagem em Destaque",
    "downloadImage": "Baixar Imagem",
    "keyword": "Palavra-chave",
    "title": "Título",
    "tags": "Tags",
    "excerpt": "Trecho",
    "content": "Conteúdo",
    "copyKeyword": "Copiar Palavra-chave",
    "copyTitle": "Copiar Título",
    "copyTags": "Copiar Tags",
    "copyExcerpt": "Copiar Trecho",
    "copyContent": "Copiar Conteúdo",
    "na": "N/A",
    "sendToWordpress": "Enviar para Meu WordPress"
  },
  "sendTOWordpressDialog": {
    "uploadStarted": "Upload para o WordPress iniciado...",
    "unknownError": "Ocorreu um erro desconhecido",
    "sendToWordpress": "Enviar para Meu WordPress",
    "uploadSuccess": "Upload Bem Sucedido!",
    "uploadFail": "Falha no Upload",
    "wordpressDomain": "Domínio do WordPress",
    "wordpressUsername": "Nome de Usuário do WordPress",
    "wordpressAppPassword": "Senha de Aplicativo do WordPress",
    "uploading": "Enviando...",
    "cancel": "Cancelar",
    "send": "Enviar"
  },
  "notConfirmed": {
    "emailConfirmationRequired": "Confirmação de Email Necessária",
    "pleaseConfirmEmail": "Por favor, confirme seu endereço de email para acessar esta aplicação.",
    "confirmationInstructionsResent": "As instruções de confirmação foram reenviadas para seu email. Clique no link quando recebê-lo para confirmar.",
    "resendConfirmationInstructions": "Reenviar Instruções de Confirmação",
    "unknownErrorSupport": "Ocorreu um erro desconhecido. Por favor, entre em contato com nossa equipe de suporte.",
    "pleaseWait": "Por favor, aguarde {{timer}} segundos antes de tentar novamente."
  },
  "changePassword": {
    "invalidOrMissingToken": "Token inválido ou ausente.",
    "invalidTokenOrExpired": "Token inválido ou expirado.",
    "passwordsDoNotMatch": "As senhas não coincidem.",
    "passwordChangedSuccess": "Senha alterada com sucesso! Redirecionando para a página de login...",
    "unknownError": "Ocorreu um erro desconhecido. Por favor, entre em contato com o suporte.",
    "subtitle": "Alterar Senha",
    "changingPasswordFor": "Alterando senha para: {{email}}",
    "newPassword": "Nova Senha",
    "confirmNewPassword": "Confirmar Nova Senha",
    "changePasswordButton": "Alterar Senha"
  },
  "forgotPassword": {
    "title": "Esqueceu a Senha",
    "emailLabel": "Endereço de Email",
    "sendButton": "Enviar Email de Redefinição de Senha",
    "instructionsSent": "As instruções de redefinição de senha foram enviadas para seu email.",
    "failedToSend": "Falha ao enviar as instruções de redefinição de senha.",
    "unknownError": "Ocorreu um erro desconhecido. Por favor, tente novamente.",
    "rememberPassword": "Lembrou sua senha?",
    "backToSignIn": "Voltar para o Login"
  },
  "signIn": {
    "subtitle": "Entrar",
    "forgotPassword": "Esqueceu sua senha?",
    "noAccount": "Não tem uma conta?",
    "signUp": "Cadastre-se",
    "noConfirmation": "Não recebeu o email de confirmação?",
    "resendConfirmation": "Reenviar Instruções de Confirmação",
    "errorInvalidRequest": "Ops! Parece que houve um erro no seu pedido. Por favor, verifique e tente novamente.",
    "errorUnauthorizedClient": "Você não tem permissão para fazer este pedido. Por favor, verifique suas credenciais ou entre em contato com o suporte.",
    "errorAccessDenied": "Acesso negado. Você não tem as permissões necessárias para este pedido.",
    "errorUnsupportedResponseType": "Este método de obtenção de um token de acesso não é suportado. Por favor, verifique o método e tente novamente.",
    "errorInvalidScope": "O escopo do seu pedido é inválido ou desconhecido. Por favor, verifique e tente novamente.",
    "errorInvalidGrant": "O endereço de email ou a senha que você inseriu estão incorretos. Por favor, tente novamente.",
    "unknownError": "Ocorreu um erro desconhecido. Por favor, entre em contato com nossa equipe de suporte em support@blogfactory.ai para obter ajuda.",
    "form": {
      "email": "Email",
      "password": "Senha",
      "emailRequired": "Email é obrigatório",
      "invalidEmail": "Endereço de email inválido",
      "passwordRequired": "Senha é obrigatória",
      "passwordMinLength": "A senha deve ter pelo menos 6 caracteres",
      "signIn": "Entrar",
      "togglePasswordVisibility": "Alternar visibilidade da senha"
    }
  },
  "signUp": {
    "passwordsDoNotMatch": "As senhas não coincidem.",
    "signUpSuccessful": "Cadastro bem sucedido! Entrando...",
    "unknownError": "Ocorreu um erro desconhecido. Por favor, entre em contato com nossa equipe de suporte.",
    "genericError": "Ocorreu um erro desconhecido. Por favor, entre em contato com nossa equipe de suporte em support@blogfactory.ai para obter ajuda.",
    "subtitle": "Cadastre-se",
    "confirmationMessage": "Você recebeu um link em sua caixa de entrada para confirmar seu email. Clique no link para confirmar e entrar.",
    "termsAndConditions": "Ao se registrar, você concorda com nossos termos e condições e política de privacidade disponíveis em",
    "alreadyHaveAccount": "Já tem uma conta?",
    "signIn": "Entrar",
    "form": {
      "fullName": "Nome Completo",
      "email": "Email",
      "password": "Senha",
      "confirmPassword": "Confirmar Senha",
      "fullNameRequired": "Nome Completo é obrigatório",
      "emailRequired": "Email é obrigatório",
      "invalidEmail": "Endereço de email inválido",
      "passwordRequired": "Senha é obrigatória",
      "passwordMinLength": "A senha deve ter pelo menos 6 caracteres",
      "confirmPasswordRequired": "Confirmar Senha é obrigatório",
      "passwordsDoNotMatch": "As senhas não coincidem",
      "togglePasswordVisibility": "Alternar visibilidade da senha",
      "toggleConfirmPasswordVisibility": "Alternar visibilidade da confirmação de senha",
      "signUp": "Cadastre-se"
    }
  },
  "emailConfirmation": {
    "unknownError": "Ocorreu um erro desconhecido. Por favor, entre em contato com nossa equipe de suporte.",
    "invalidOrMissingToken": "Token de confirmação inválido ou ausente.",
    "tryAgainOrContactSupport": "Por favor, tente novamente ou entre em contato com o suporte se o problema persistir."
  },
  "resendConfirmationInstructions": {
    "header": "Reenviar Instruções de Confirmação",
    "instructionsResent": "As instruções de confirmação foram reenviadas para seu email.",
    "enterEmail": "Digite seu endereço de email abaixo para reenviar as instruções de confirmação.",
    "emailLabel": "Endereço de Email",
    "resendButton": "Reenviar Instruções de Confirmação",
    "backToSignIn": "Voltar para o Login",
    "unknownError": "Ocorreu um erro desconhecido. Por favor, entre em contato com nossa equipe de suporte."
  },
  "userCreditsButton": {
    "tooltip": "Clique aqui para comprar créditos",
    "credits_one": "{{count}} Crédito",
    "credits_other": "{{count}} Créditos",
    "buyCredits": "{{count}} Créditos - Comprar"
  },
  "buyCreditsDialog": {
    "title": "Comprar Créditos",
    "enterNumberOfCredits": "Digite o número de créditos que você deseja comprar:",
    "cancel": "Cancelar",
    "buyCredits": "Comprar Créditos",
    "processing": "Processando...",
    "invalidNumberOfCredits": "Por favor, insira um número válido de créditos.",
    "stripeCheckoutFailed": "Falha ao iniciar o checkout do Stripe."
  }
}

import {useEffect, useState} from "react";
import {useAuth} from "./useAuth";
import ApiClient from "../api/api_client";

export const useApi = () => {
    const authData = useAuth();
    const [apiClient, setApiClient] = useState(new ApiClient(authData));
    useEffect(() => {
        setApiClient(new ApiClient(authData));
    }, [authData.accessToken]);

    return {client: apiClient.client};
}

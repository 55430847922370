import React, {useEffect, useState} from 'react';
import {Box, Container, CssBaseline, Link, Typography} from '@mui/material';
import Form from "./Form";
import {forgotPasswordPath, homePath, resendConfirmationInstructions, signUpPath} from "../../../routes/routes";
import {clientId, clientSecret} from "../../../constants";
import {userSignInPath} from "../../../api/paths";
import {useAuth} from "../../../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import {useApi} from "../../../hooks/useApi";
import Header from "../Header";
import {useTranslation} from 'react-i18next';

const ERROR_MAPPING = {
    'invalid_request': 'common:signIn.errorInvalidRequest',
    'unauthorized_client': 'common:signIn.errorUnauthorizedClient',
    'access_denied': 'common:signIn.errorAccessDenied',
    'unsupported_response_type': 'common:signIn.errorUnsupportedResponseType',
    'invalid_scope': 'common:signIn.errorInvalidScope',
    'invalid_grant': 'common:signIn.errorInvalidGrant',
};

const SignIn = () => {
    const {t} = useTranslation();
    const {setAuthentication, authenticated} = useAuth();
    const {client} = useApi();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (authenticated()) {
            navigate(homePath());
        }
    }, []);

    const handleSignIn = async (email, password) => {
        setLoading(true);
        setError('');

        try {
            await client.post(userSignInPath, {
                grant_type: 'password',
                client_id: clientId,
                client_secret: clientSecret,
                redirect_uri: 'http://localhost:3000/',
                email: email,
                password: password
            })
                .then(result => handleSuccess(result))
                .catch(error => handleRequestError(error));
        } catch (error) {
            handleGenericError(error);
        }
    };

    const handleSuccess = ({data}) => {
        setAuthentication(data.access_token, data.expires_in, data.refresh_token);
        window.location.href = homePath();
    };

    const handleRequestError = (error) => {
        console.log(error);
        setLoading(false);
        const errorKey = error.response?.data?.error;
        const errorMessage = t(ERROR_MAPPING[errorKey] || 'common:signIn.unknownError');
        setError(errorMessage);
    };

    const handleGenericError = (error) => {
        setLoading(false);
        setError(error.message || t('common:signIn.unknownError'));
        console.info(error);
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 2
            }}>
                <Header
                    subtitle={t('common:signIn.subtitle')}
                />
                <Form
                    email={email}
                    password={password}
                    loading={loading}
                    handleSignIn={handleSignIn}
                    setEmail={setEmail}
                    setPassword={setPassword}
                    error={error}
                />
                <Typography variant="body2" sx={{mt: 2}}>
                    <Link href={forgotPasswordPath()}>{t('common:signIn.forgotPassword')}</Link>
                </Typography>
                <Typography variant="body2" sx={{mt: 2}}>
                    {t('common:signIn.noAccount')} <Link href={signUpPath()}>{t('common:signIn.signUp')}</Link>
                </Typography>
                <Typography variant="body2" sx={{mt: 2}}>
                    {t('common:signIn.noConfirmation')} <Link
                    href={resendConfirmationInstructions()}>{t('common:signIn.resendConfirmation')}</Link>
                </Typography>
            </Box>
        </Container>
    );
};

export default SignIn;

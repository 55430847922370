import React, {useState} from "react";
import {Alert, Box, Button, CircularProgress, Container, CssBaseline, Link, TextField, Typography} from '@mui/material';
import {useSnackbar} from 'notistack';
import {userPasswordRecoverPath} from "../../../api/paths";
import {useApi} from "../../../hooks/useApi";
import {signInPath} from "../../../routes/routes";
import {useTranslation} from 'react-i18next';

const ForgotPassword = () => {
    const {t} = useTranslation();
    const {client} = useApi();
    const {enqueueSnackbar} = useSnackbar();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            await client.post(userPasswordRecoverPath, {
                user: {email}
            });
            setSuccess(true);
            enqueueSnackbar(t('common:forgotPassword.instructionsSent'), {variant: 'success'});
        } catch (err) {
            setError(err.response?.data?.error || t('common:forgotPassword.unknownError'));
            enqueueSnackbar(t('common:forgotPassword.failedToSend'), {variant: 'error'});
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    {t('common:forgotPassword.title')}
                </Typography>
                <Box component="form" onSubmit={handleForgotPassword} sx={{mt: 1}}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={t('common:forgotPassword.emailLabel')}
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={loading}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{mt: 3, mb: 2}}
                        disabled={loading}
                    >
                        {loading ?
                            <CircularProgress size={24} color="secondary"/> : t('common:forgotPassword.sendButton')}
                    </Button>
                    {success && <Alert severity="success">{t('common:forgotPassword.instructionsSent')}</Alert>}
                    {error && <Alert severity="error" sx={{mt: 2}}>{error}</Alert>}
                    <Typography variant="body2" sx={{mt: 2}}>
                        {t('common:forgotPassword.rememberPassword')}{' '}
                        <Link href={signInPath()}>{t('common:forgotPassword.backToSignIn')}</Link>
                    </Typography>
                </Box>
            </Box>
        </Container>
    );
};

export default ForgotPassword;

import React, { useState, useCallback } from 'react';

import ModalContext from "../contexts/modal-context";
import uid from "../helpers/uid";



const isKeyMatchRootId = (key, rootId) => {
  const [keyRootId] = key.split('.');
  return keyRootId === rootId;
};

const ModalProvider = ({ children }) => {
  const [state, setState] = useState({});

  const updateModal = useCallback(
    (id, { open, ...props }) => setState(prevState => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        props: {
          ...(prevState[id] && prevState[id].props),
          ...props,
        },
      },
    })),
    []
  );

  const destroyModal = useCallback(
    id => setState(({ [id]: _, ...newState }) => newState),
    []
  );

  const destroyModalsByRootId = useCallback(
    rootId => setState(prevState => Object.keys(prevState)
      .filter(key => !isKeyMatchRootId(key, rootId))
      .reduce((obj, key) => {
        obj[key] = prevState[key];
        return obj;
      }, {})),
    []
  );

  const makeShowModal = useCallback(
    rootId => (component, props, options) => {
      const id = `${rootId}.${uid(8)}`;

      setState(prevState => ({
        ...prevState,
        [id]: {
          component,
          props: {
            ...props,
            open: true,
          },
          options,
        },
      }));

      return {
        id,
        destroy: () => destroyModal(id),
        update: newProps => updateModal(id, newProps),
      };
    },
    [destroyModal, updateModal]
  );

  const renderState = () => Object.keys(state).map((id) => {
    const { component: Component, props } = state[id];

    const handleClose = (...args) => {
      destroyModal(id);

      if (props && props.onClose) {
        props.onClose(...args);
      }
    };

    return (
      <Component
        {...props}
        key={id}
        onClose={handleClose}
        modalId={id}
      />
    );
  });

  return (
    <ModalContext.Provider
      value={{
        makeShowModal,
        destroyModal,
        destroyModalsByRootId,
        updateModal,
        state,
      }}
    >
      {children}
      {renderState()}
    </ModalContext.Provider>
  );
};

export default ModalProvider;

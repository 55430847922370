import React from 'react';
import { useSnackbar } from 'notistack';

import { IconButton } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

const SnackbarCloseButton = ({ id }) => {
  const { closeSnackbar } = useSnackbar();

  const onClick = () => closeSnackbar(id);

  return (
    <IconButton
      onClick={onClick}
      size="small"
      sx={{ color: 'white' }}
    >
      <CloseIcon/>
    </IconButton>
  );
};

export default SnackbarCloseButton;

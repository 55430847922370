export const mapOperatorToRansack = (operator) => {
    switch (operator) {
        case 'contains': return 'cont';
        case 'equals': return 'eq';
        case 'starts with': return 'start';
        case 'ends with': return 'end';
        case 'is empty': return 'blank';
        case 'is not empty': return 'present';
        case 'is any of': return 'in';
        default: return 'eq';
    }
};

import React from 'react';
import {BrowserRouter} from 'react-router-dom';

import './fonts';

import {SnackbarProvider} from "./hooks/useSnackbar";

import SnackbarCloseButton from "./components/SnackbarCloseButton";
import AppRoutes from "./AppRoutes";
import AuthenticationProvider from "./providers/AuthenticationProvider";
import Theme from "./Theme";
import ModalProvider from "./providers/ModalProvider";
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n";

const App = () => (
    <I18nextProvider i18n={i18n}>
        <BrowserRouter>
            <Theme>
                <SnackbarProvider action={id => <SnackbarCloseButton id={id}/>}>
                    <AuthenticationProvider>
                        <ModalProvider>
                            <AppRoutes/>
                        </ModalProvider>
                    </AuthenticationProvider>
                </SnackbarProvider>
            </Theme>
        </BrowserRouter>
    </I18nextProvider>
)

export default App;
import React, {useContext} from 'react';
import {ButtonBase, Tooltip, Typography} from '@mui/material';
import {MonetizationOn as CoinIcon} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';
import CurrentUserContext from "../../contexts/current-user-context";
import useModal from "../../hooks/useModal";
import BuyCreditsDialog from "./BuyCreditsDialog";

const UserCreditsButton = () => {
    const {t} = useTranslation();
    const {currentUser} = useContext(CurrentUserContext);
    const {showModal} = useModal();

    const handleOpenDialog = () => {
        showModal(BuyCreditsDialog, {});
    };

    const credits = currentUser.credits || 0;
    const displayText = credits > 0 ? t('common:userCreditsButton.credits', {count: credits}) : t('common:userCreditsButton.buyCredits', {count: credits});

    return (
        <Tooltip title={t('common:userCreditsButton.tooltip')} arrow>
            <ButtonBase onClick={handleOpenDialog} style={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: '8px',
                padding: '8px',
                marginRight: '16px',
                backgroundColor: 'rgba(0, 0, 0, 0.2)'
            }}>
                <CoinIcon style={{color: '#ffd700', marginRight: '8px'}}/>
                <Typography variant="body1" color="inherit">
                    {displayText}
                </Typography>
            </ButtonBase>
        </Tooltip>
    );
};

export default UserCreditsButton;
